const { event } = require("jquery");

// for bene details
var rateAccount;
var rateCash;
var AgentCostRate;
var ltd;
var dtl;
var product;
var transferCountry;
var agent;
var ltdMax;
var CvLtd;
var banks;
var branches;
let transferCountryName;

var filterData = {};
const urlParams = new URLSearchParams(window.location.search);
const ptcn_no = urlParams.get("ptcn_no");
const transfer_country = $("#transfer-to").val();
const exist_agent = $("#transaction-agent").val();
const exist_product = $("#transaction-products").val();
const exist_dtl = $("#dtl").val();
const exist_ltd = $("#ltd").val();
const deliver_type = $("#transfer-type").val();
const customer_type = $('#customer-type').val();
var locationDetailsInput = $("#location-details-inputs");
var helpLineDetailsInput = $("#helpline-details-inputs");
const idTypeEl = $(
  "#transfer_transaction_customer_attributes_identification_type_id"
);
if (ptcn_no) filterData.ptcn_no = ptcn_no;
if (transfer_country) filterData.transfer_country = transfer_country;
if (exist_agent) filterData.agent = exist_agent;
if (exist_product) filterData.product = exist_product;
if (exist_dtl) dtl = exist_dtl;
if (exist_ltd) ltd = exist_ltd;
if (deliver_type) filterData.transfer_type = deliver_type;
if (customer_type) filterData.customer_type = customer_type;

function fixDateFormat(dateTime) {
  if (dateTime) {
    const isoDate = dateTime;
    const date = new Date(isoDate);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  }
}

function fillFormFields(currentClass, cValues) {
  $(`${currentClass}_first_name`).val(cValues.first_name);
  $(`${currentClass}_middle_name`).val(cValues.middle_name);
  $(`${currentClass}_last_name`).val(cValues.last_name);
  $(`${currentClass}_surname`).val(cValues.surname);
  $(`${currentClass}_primary_cell_number`).val(cValues.primary_cell_number);
  $(`${currentClass}_additional_cell_number`).val(
    cValues.additional_cell_number
  );
  $(`${currentClass}_primary_address`).val(cValues.primary_address);
  $(`${currentClass}_secondary_address`).val(cValues.secondary_address);
  $(`${currentClass}_date_of_birth`).val(cValues.date_of_birth);
  $(`${currentClass}_occupation`).val(cValues.occupation);
  $(`${currentClass}_nationality_id`).val(cValues.nationality_id);
  $(`${currentClass}_country_id`).val(cValues.country_id);
  $(`${currentClass}_primary_cell_no_code`).val(cValues.primary_cell_no_code);
  $(`${currentClass}_additional_cell_no_code`).val(
    cValues.additional_cell_no_code
  );
  $(`${currentClass}_identification_type_id`).val(
    cValues.identification_type_id
  );

  $(`${currentClass}_id_issue_date`).val(fixDateFormat(cValues.id_issue_date));
  $(`${currentClass}_id_expiry_date`).val(
    fixDateFormat(cValues.id_expiry_date)
  );

  $(`${currentClass}_id_issue_at`).val(cValues.id_issue_at);
  $(`${currentClass}_id_no`).val(cValues.id_no);
  $(`${currentClass}_city`).val(cValues.city);
  $(`${currentClass}_customer_type`).val(cValues.customer_type);
  $(`${currentClass}_source_of_income_id`).val(cValues.source_of_income_id);
  $(`${currentClass}_gender`).val(cValues.gender);
}

function fill_identities_values(id, cValues) {
  const currentTarg = id == "customer" ? "customer_select" : "bene_select";
  const currentClass = `#transfer_transaction_${id}_attributes`;

  if (
    $(`#${currentTarg}`).val() !== "" &&
    $(`#${currentTarg}`).val() !== "Please select"
  ) {
    fillFormFields(currentClass, cValues);
  } else {
    fillFormFields(currentClass, {});
  }
}

function populateDropdownOptions(id, options, selectedValue) {
  if (options !== null && options.length > 0) {
    $("#" + id)
      .empty()
      .html("<option value=''>Please select</option>");
    if (id == "bene-currencies" && options.length === 1) {
      selectedValue = options[0].id;
      if (selectedValue != filterData.bene_currency)
        filterData.bene_currency = selectedValue;
    }
    options.forEach(function (option) {
      const optionValue = option.id;
      const optionText = option.name
        ? option.name
        : option.first_name && option.last_name
          ? `${option.first_name} ${option.last_name}`
          : option.first_name
            ? option.first_name
            : option.surname
              ? option.surname
              : "";
      const selected = selectedValue == optionValue ? "selected" : "";

      $("#" + id).append(
        '<option value="' +
        optionValue +
        '" ' +
        selected +
        ">" +
        optionText +
        "</option>"
      );
    });
    // if (id == "bene-currencies" && options.length === 1) {
    //   $("#" + id).change();
    // }
  } else {
    const targetElement =
      id === "bene_select" ? $("#bene_select") : $("#customer_select");
    let id_details;
    let id_render;
    if (id === "bene_select") {
      id_details = "bene_details";
      id_render = "bene_render";
    } else {
      id_details = "customer_details";
      id_render = "customer_render";
    }

    if (id === "bene_select" || id === "customer_select") {
      targetElement.empty().html("<option value=''>Please select</option>");
      $("#" + id_details).html("");
      $("#" + id_render).show();
    } else {
      $(
        "#ltd, #dtl, #transfer-amount, #backend-charge, #bene-amount, #total-recievable, #recievable-vat, #charge-amount, #lc-amount"
      ).val("");
    }
  }
}

function validateTransferType() {
  if (!filterData.transfer_country) {
    $("#transfer-type").val("");
    // $("#transfer-type").attr("disabled", "disabled");
    $("#bene-currencies")
      .empty()
      .html("<option value=''>Please select</option>");
  } else {
    // $("#transfer-type").removeAttr("disabled");
  }
}

function setCurrencyCode(lc, dc) {
  if (lc) $(".local-currency-code").html(lc.currency.code);
  else $(".local-currency-code").html("...");

  if (dc) $(".dest-currency-code").html(dc.currency.code);
  else $(".dest-currency-code").html("...");
}

function fetchData() {
  return new Promise(function (resolve, reject) {
    $.ajax({
      type: "GET",
      url: window.location.pathname,
      dataType: "json",
      data: filterData,
      error: function (xhr, status, error) {
        const e = "AJAX Error: " + status + error;
        console.error(e);
        reject(e);
      },
      success: function (response) {
        if (!response) {
          resolve();
          return;
        }

        if (response.location_details) {
          locationDetailsInput.val(response.location_details);
        } else {
          locationDetailsInput.val("");
        }
        if (response.help_line) {
          helpLineDetailsInput.val(response.help_line);
        } else {
          helpLineDetailsInput.val("");
        }
        if (response.min_trnx || response.max_trnx) {
          const minValue = response.min_trnx || 0;
          const maxValue = response.max_trnx || 0;
          $("#single-txn-limit-input").val(`${minValue} - ${maxValue}`);
        } else {
          $("#single-txn-limit-input").val("0 - 0");
        }
        // console.log(response);
        // console.log(filterData);

        const transferCountries = response.transfer_countries;
        populateDropdownOptions(
          "transfer-to",
          transferCountries || [],
          filterData.transfer_country
        );
        validateTransferType();

        const beneCurrencies = response.bene_currencies;
        populateDropdownOptions(
          "bene-currencies",
          (beneCurrencies || []).map(function (bc) {
            return { id: bc.id, name: bc.label_with_code };
          }),
          filterData.bene_currency
        );

        const preferredProduct = response.preferred_product;
        if (preferredProduct) filterData.product = preferredProduct;

        const products = response.products;

        populateDropdownOptions(
          "transaction-products",
          products || [],
          filterData.product
        );
        const payingAgents = response.paying_agents;
        populateDropdownOptions(
          "transaction-paying-agent",
          payingAgents || [],
          filterData.paying_agent
        );
        const subAgents = response.sub_agents;
        populateDropdownOptions(
          "transaction-sub-agent",
          subAgents || [],
          filterData.sub_agent
        );
        const pickup_centers = response.pickup_centers
        if (pickup_centers && pickup_centers.length > 0) {
          $("#pickup_center").show();
          $("#paying_agent_id").hide();
          populateDropdownOptions(
            "transaction-pickup-center",
            pickup_centers || [],
            filterData.bank
          );
        } else {
          $("#pickup_center").hide();
        }
        banks = response.banks;
        populateDropdownOptions(
          "transaction-bank",
          banks || [],
          filterData.bank
        );
        branches = response.branches;
        populateDropdownOptions(
          "transaction-branch",
          branches || [],
          filterData.branch
        );
        const pots = response.pots;
        populateDropdownOptions("txn-pots", pots || [], filterData.pots);
        // if (response.product_parameters) {
        //   console.log(response.product_parameters);
        //   response.product_parameters.forEach(function (param) {
        //     if (param.visible_in_send === true) {
        //       $(`#${param.field_name}, .${param.field_name}`)
        //         .show()
        //         .find("input, select")
        //         .removeAttr("disabled");
        //     } else {
        //       $(`#${param.field_name}, .${param.field_name}`)
        //         .hide()
        //         .find("input, select")
        //         .attr("disabled", "disabled");
        //     }
        //   });
        // }

        const identification_types = response.identification_types;
        populateDropdownOptions("transfer_transaction_customer_attributes_identification_type_id", identification_types || [], filterData.identification_type);

        const sois = response.sois;
        populateDropdownOptions("transfer_transaction_customer_attributes_source_of_income_id", sois || [], filterData.soi);

        const customers = response.customers;
        populateDropdownOptions(
          "customer_select",
          customers || [],
          filterData.customer
        );
        const beneies = response.beneies;
        populateDropdownOptions("bene_select", beneies || [], filterData.bene);
        const customer_values = response.customer_values;
        if (customer_values && customer_values.id == filterData.customer)
          fill_identities_values("customer", customer_values);
        if (filterData.product !== undefined) {
          if (response.currency_rate) {
            ltd = response.ltd;
            ltdMax = response.ltd;
            dtl = response.dtl;
            if (ltd !== undefined && ltd > 0 && dtl !== undefined && dtl > 0) {
              $("#ltd, #transfer-amount, #lc-amount").prop("disabled", false);
              $("#ltd").empty().val(ltd);
              $("#dtl").empty().val(dtl);
            } else {
              $("#ltd, #transfer-amount, #lc-amount").prop("disabled", true);
              showAlert("danger", "Currency Rates are not defined");
            }
          } else {
            $("#ltd, #transfer-amount, #lc-amount").prop("disabled", true);
            showAlert("danger", "Currency Rates are not defined");
          }
        }

        if (response.cv_ltd && response.cv_ltd < ltdMax) {
          CvLtd = response.cv_ltd;
          $(".ltd-rate").text(`(${CvLtd} - ${ltdMax})`);
        } else if (response.cv_ltd && response.cv_ltd >= ltdMax) {
          CvLtd = ltdMax; // Set cvLtd to ltdMax
          $(".ltd-rate").text(`(${CvLtd} - ${ltdMax})`);
        } else {
          $(".ltd-rate").empty();
        }

        setCurrencyCode(
          response.local_currency,
          beneCurrencies.find((c) => c.id == filterData.bene_currency)
        );

        if (filterData.product) updateProductParameters();

        resolve(response);
      },
    });
  });
}

$(document).on("change", "#new_transfer_transaction select", function () {
  $("#new_transfer_transaction .alert").remove();
});

$(document).on("change", "#new_transfer_transaction input", function () {
  $("#new_transfer_transaction .alert").remove();
});

$(document).on("change", "#transaction-agent", function () {
  $("#transfer-to, #transaction-products")
    .empty()
    .html("<option value=''>Please select</option>");
  const agent = $(this).val();
  filterData = { agent: agent };
  const urlParams = new URLSearchParams(window.location.search);
  const ptcn_no = urlParams.get("ptcn_no");
  if (ptcn_no) filterData.ptcn_no = ptcn_no;
  fetchData().then(function () { });
});

$(document).on("change", "#transfer-to", function () {
  const transfer_country = $(this).val();
  transferCountryName = $(this).find("option:selected").text();
  if (
    transferCountryName === "Pakistan" ||
    transferCountryName === "India" ||
    transferCountryName === "Bangladesh"
  ) {
    $(".be-charge").hide();
  } else {
    $(".be-charge").show();
  }

  $("#transaction-products")
    .empty()
    .html("<option value=''>Please select</option>");
  filterData.transfer_country = transfer_country;
  // delete filterData.transfer_type;
  delete filterData.bene_currency;
  delete filterData.product;
  delete filterData.bank;
  delete filterData.branch;
  delete filterData.paying_agent;
  delete filterData.sub_agent;

  fetchData().then(function () { });
});

$(document).on("change", "#transfer-type", function () {
  const transfer_type = $(this).val();
  const validValues = ["Cash Transfer", "Bank Transfer", "Mobile Wallet"];
  $(" #swift_ifsc-input, #account-input").val("");
  $("#transaction-products, #transaction-bank")
    .empty()
    .html("<option value=''>Please select</option>");
  if (validValues.includes(transfer_type)) {
    filterData.transfer_type = transfer_type;
    delete filterData.bene_currency;
    delete filterData.product;
    fetchData().then(function () { });
  } else {
    $(
      "#ltd , #dtl , #transfer-amount , #backend-charge , #bene-amount, #total-recievable , #recievable-vat , #recievable-vat, #charge-amount, #lc-amount"
    ).val("");
    $("#bene-currencies , #transaction-products")
      .empty()
      .html("<option value=''>Please select</option>");
  }
  if (transfer_type == "Bank Transfer") {
    // $("#bank-account-form").removeClass("hidden");
    $("#products-dropdown").addClass("hidden");
  } else {
    // $("#bank-account-form").addClass("hidden");
    $("#products-dropdown").removeClass("hidden");
  }
});

$(document).on("change", "#bene-currencies", function () {
  const destination_country = $("#transfer-to").val();
  const agent = $("#transaction-agent").val();
  const deliver_type = $("#transfer-type").val();
  const bene_currency = $(this).val();

  // $("#transaction-products")
  //   .empty()
  //   .html("<option value=''>Please select</option>");
  filterData.bene_currency = bene_currency;
  if (deliver_type === "Bank Transfer") filterData.transfer_type = deliver_type;

  // delete filterData.product;
  fetchData().then(function (response) {
    // if (!response) return;
    // const preferredProduct = response.preferred_product;
    // $("#transaction-products")
    //   .html(
    //     "<option value='" +
    //     preferredProduct +
    //     "' selected>" +
    //     preferredProduct +
    //     "</option>"
    //   );
    // .trigger("change");
  });
});

$(document).on("change", "#transaction-products", function () {
  delete filterData.bank;
  delete filterData.branch;
  delete filterData.paying_agent;
  delete filterData.sub_agent;
  const product = $(this).val();
  filterData.product = product;
  fetchData().then(function () { });
  // fetchIdTypes(filterData.transfer_country, product)
});

$(document).on("change", "#txn-pots", function () {
  const pots = $(this).val();
  filterData.pots = pots;
  fetchData().then(function () { });
});

$(document).on("change", "#transaction-paying-agent", function () {
  const paying_agent = $(this).val();
  const paying_agent_text = $(this).find("option:selected").text();
  if (paying_agent_text.toLowerCase().includes("payout anywhere")) {
    $("#sub_agent_id").hide();
  }
  filterData.paying_agent = paying_agent;
  delete filterData.sub_agent;
  fetchData().then(function () { });
});

$(document).on("change", "#transaction-sub-agent", function () {
  const sub_agent = $(this).val();
  filterData.sub_agent = sub_agent;
  fetchData().then(function () { });
});

$(document).on("change", "#transaction-bank", function () {
  const bank = $(this).val();
  filterData.bank = bank;
  delete filterData.branch;
  fetchData().then(function () { });
});

$(document).on("change", "#transaction-branch", function () {
  const branch = $(this).val();
  filterData.branch = branch;
  fetchData().then(function () { });
  let branchData = branches.find((obj) => obj.id == branch);
  $("#swift_ifsc-input").val(branchData.swift_code);
});
$(document).on("change", "#customer_select", function () {
  const customer = $(this).val();
  filterData.customer = customer;
  fetchData().then(function () { });
});

// $("#lc-amount").blur(function(){
//   var lcAmount = $("#lc-amount").val();
//   $.ajax({
//     type: "GET",
//     url: window.location.pathname,
//     dataType: "json",
//     data: {agent_id: agent, product_id: product },
//     error: function(xhr, status, error) {
//       console.error("AJAX Error: " + status + error);
//     },
//     success: function(response) {
//       var productDetail = response.product_detail;
//       var countryDetail = response.country_detail;

//       var vatToPaid = countryDetail.vat_to_paid;
//         $("#backend-charge").val(productDetail.backend_charge);
//         $("#charge-amount").val(productDetail.charge);
//         $("#bene-amount").val(dtl*lcAmount);
//         var transferAmount = parseFloat($("#bene-amount").val()) + parseFloat($("#backend-charge").val());
//       $("#transfer-amount").val(transferAmount);

//         var totalRecievable = parseFloat(lcAmount) +  parseFloat($("#charge-amount").val());

//         $("#total-recievable").val(totalRecievable);
//         var recievablePercentage = vatToPaid * ($("#charge-amount").val() /100 )
//         $("#recievable-vat").val(recievablePercentage);
//         $("#vat-amount").val(totalRecievable + recievablePercentage )
//     }
// })
//   })
// $("#transfer-amount").blur(function() {
//   var lcAmount = parseFloat($("#transfer-amount").val() / parseFloat(dtl) );
//   $("#lc-amount").val(lcAmount)
//   $.ajax({
//     type: "GET",
//     url: window.location.pathname,
//     dataType: "json",
//     data: {agent_id: agent, product_id: product},
//     error: function(xhr, status, error) {
//       console.error("AJAX Error: " + status + error);
//     },
//     success: function(response) {
//       var productDetail = response.product_detail;
//       var countryDetail = response.country_detail;
//       var vatToPaid = countryDetail.vat_to_paid;
//         $("#backend-charge").val(productDetail.backend_charge);
//         $("#charge-amount").val(productDetail.charge);
//         $("#bene-amount").val(dtl*lcAmount);

//         var totalRecievable = parseFloat(lcAmount) +  parseFloat($("#charge-amount").val());

//         $("#total-recievable").val(totalRecievable);
//         var recievablePercentage = vatToPaid * ($("#charge-amount").val() /100 )
//         $("#recievable-vat").val(recievablePercentage);
//         $("#vat-amount").val(totalRecievable + recievablePercentage )
//     }
// })
// })

function showAlert(type, message) {
  var alertMsg = `
    <div class="mb-2 alert alert_${type}">
      <strong class="uppercase"></strong>
      ${message}
      <button type="button" class="dismiss la la-times" data-dismiss="alert"></button>
    </div>
  `;

  var container = $("#new_transfer_transaction");
  if (!container.find(`.alert.alert_${type}:contains(${message})`).length) {
    container.prepend(alertMsg);
  }
}

function formatToTwoDecimalPlaces(value) {
  return Number(parseFloat(value).toFixed(2));
}

function updateValues(lcAmount, transferAmountSelected = false) {
  ltd = $("#ltd").val();
  $.ajax({
    type: "GET",
    url: window.location.pathname,
    dataType: "json",
    data: {
      dest_country: filterData.transfer_country,
      agent_id: filterData.agent,
      product_id: filterData.product,
      ptcn_no: filterData.ptcn_no,
      lc_amount: lcAmount,
      dtl: dtl,
      ltd: ltd,
    },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      $("#backend-charge").val("");
      $("#charge-amount").val("");
      $("#bene-amount").val("");

      $("#total-recievable").val("");
      $("#recievable-vat").val("");
      $("#vat-amount").val("");
      $(".alert").remove();
      const minAmount = formatToTwoDecimalPlaces(response.min_amount);
      const maxAmount = formatToTwoDecimalPlaces(response.max_amount);
      const transferAmount = formatToTwoDecimalPlaces(response.transfer_amount);
      const backendCharge = formatToTwoDecimalPlaces(response.backend_charge);
      const charge = formatToTwoDecimalPlaces(response.charge);
      const beneAmount = transferAmountSelected
        ? formatToTwoDecimalPlaces($("#transfer-amount").val()) - backendCharge
        : formatToTwoDecimalPlaces(response.bene_amount);
      const totalRecievable = formatToTwoDecimalPlaces(
        response.total_recievable
      );
      const netTotalRecievable = formatToTwoDecimalPlaces(
        response.net_total_recievable
      );
      const vatAmount = formatToTwoDecimalPlaces(response.vat_amount);
      if (minAmount != null && minAmount > 0 && transferAmount < minAmount) {
        showAlert(
          "danger",
          `Transaction FC amount should be between <strong>${minAmount} -
            ${formatToTwoDecimalPlaces(maxAmount)}</strong>`
        );
        $("#transfer-amount").val(formatToTwoDecimalPlaces(transferAmount));
      } else if (
        maxAmount != null &&
        maxAmount > 0 &&
        transferAmount > maxAmount
      ) {
        showAlert(
          "danger",
          `Transaction FC amount should be between <strong>${minAmount} -
            ${maxAmount}</strong>`
        );
        $("#transfer-amount").val(transferAmount);
      } else {
        if (!transferAmountSelected) {
          $("#transfer-amount").empty().val(transferAmount);
        }
        if (
          transferCountryName !== "Pakistan" ||
          transferCountryName !== "India" ||
          transferCountryName !== "Bangladesh"
        ) {
          $("#backend-charge").val(backendCharge);
        }
        $("#charge-amount").val(charge);
        $("#bene-amount").val(beneAmount);
        $("#total-recievable").val(totalRecievable);
        $("#recievable-vat").val(netTotalRecievable);
        $("#vat-amount").val(vatAmount);
      }
    },
  });
}

// Handle alert dismissals
$("#new_transfer_transaction").on("click", ".dismiss", function () {
  $(this).closest(".alert").remove();
});

var lcAmountInitial;

$("#lc-amount").focus(function () {
  lcAmountInitial = $(this).val();
});

$("#lc-amount").blur(function () {
  var lcAmount = $(this).val();
  if (lcAmount !== lcAmountInitial) {
    updateValues(lcAmount);
    // onValueAdded();
  }
});

$("#transfer-amount").blur(function () {
  var transferAmount = parseFloat($("#transfer-amount").val());
  var lcAmount = formatToTwoDecimalPlaces(transferAmount * parseFloat(dtl));

  if (lcAmount !== $("#lc-amount").val()) {
    $("#lc-amount").val(lcAmount);
    updateValues(lcAmount, true);
    // onValueAdded();
  }
});

$("#ltd").blur(function () {
  var lcAmount = $("#lc-amount").val();
  var enteredValue = parseFloat($(this).val());
  if (enteredValue < CvLtd || enteredValue > ltdMax) {
    showAlert(
      "danger",
      `Teller Rate should be between <strong>${CvLtd} - ${ltdMax}</strong>`
    );
    $(this).val(ltd);
    return;
  }
  $("#dtl").val((1 / $(this).val()).toFixed(9));
  if (lcAmount) updateValues(lcAmount);
});

// $(document).ready(function () {
//   var transferCountry = $("#transfer-to").val();
//   if (transferCountry) {
//     var url = window.location.pathname;
//     var selectedBeneCurrency = $("#bene-currencies").data("selected-value");
//     var selectedBene = $("#bene_select").data("selected-value");
//     var selectedProduct = $("#transaction-products").data("selected-value");

//     $.ajax({
//       type: "GET",
//       url: url,
//       dataType: "json",
//       data: { transfer_country: transferCountry, ptcn_no: filterData.ptcn_no },
//       error: function (xhr, status, error) {
//         console.error("AJAX Error: " + status + error);
//       },
//       success: function (response) {
//         var beneCurrencies = response.bene_currencies;
//         var beneies = response.beneies;
//         populateDropdownOptions(
//           "bene-currencies",
//           beneCurrencies,
//           selectedBeneCurrency
//         );
//         populateDropdownOptions("bene_select", beneies, selectedBene);
//         populateDropdownOptions("transaction-products", selectedProduct);
//       },
//     });
//   }
// });

function updateProductParameters() {
  const customerType = $("#customer-type").val();
  const product = $("#transaction-products").val();
  const deliveryType = $("#transfer-type").val();

  if (!product) return;

  $.ajax({
    url: window.location.pathname,
    type: "GET",
    dataType: "json",
    data: {
      search_product_parameters: true,
      product: product,
      customer_type: customerType,
      transfer_type: deliveryType,
      // ptcn_no: filterData.ptcn_no,
    },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      if (response.product_parameters) {
        console.log(response.product_parameters);
        response.product_parameters.forEach(function (param) {
          var $field = $(`#${param.field_name}, .${param.field_name}`);
          if (param.visible_in_send === true) {
            $(`#${param.field_name}, .${param.field_name}`)
              .show()
              .find("input, select")
              .removeAttr("disabled");
          } else {
            $(`#${param.field_name}, .${param.field_name}`)
              .hide()
              .find("input, select")
              .attr("disabled", "disabled");
          }
          if (param.field_name == "bank" && param.visible_in_send == true) {
            if (param.is_bank_editable == true) {
              $(".bank-dropdown").hide();
              $(".bank-name").show();
              $("#bank-name-field").attr("disabled", false);
            } else {
              $(".bank-dropdown").show();
              $(".bank-name").hide();
              $("#bank-name-field").attr("disabled", "disabled");
            }
          } else if (
            param.field_name == "branch" &&
            param.visible_in_send == true
          ) {
            if (param.is_branch_editable == true) {
              $(".branch-dropdown").hide();
              $(".branch-name").show();
              $("#branch-name-field").attr("disabled", false);
            } else {
              $(".branch-dropdown").show();
              $(".branch-name").hide();
              $("#bank-name-field").attr("disabled", "disabled");
            }
          }
          $field.find(".mandatory").remove();
          if (param.mandatory_in_send == true) {
            $field
              .find("label")
              .append('<span class="mandatory text-red-500"> *</span>');
          }
        });
      }
    },
  });
}
$(window).on("load", updateProductParameters);
$(document).on("change", "#customer-type", updateProductParameters);
// $(document).on("change", "#transaction-products", updateProductParameters);

// function onValueAdded() {
//   setTimeout(function () {
//     const value = $("#recievable-vat").val();
//     let disabled = !value;
//     if (!disabled) disabled = value == "0";
//     // console.log('enabled the button');
//     $("#details-next-button").prop("disabled", disabled);
//   }, 300);
// }

$(document).on("change", "#bene_select", function () {
  beneSelect = $(this).val();
  $.ajax({
    url: window.location.pathname,
    type: "GET",
    dataType: "json",
    data: { bene_select: beneSelect, ptcn_no: filterData.ptcn_no },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      beneValues = response.bene_values;
      fill_identities_values("bene", beneValues);
    },
  });
});

$(document).ready(function () {
  $("#tab-amount-info").on("keydown", function (event) {
    if (event.keyCode === 13 && !$(event.target).is("select, textarea")) {
      if (
        !$(event.target).is('[data-direction="next"]') &&
        !$(event.target).is('[data-direction="previous"]')
      ) {
        event.preventDefault();
      }
    }
  });

  $("#tab-customer-details").on("keydown", function (event) {
    if (event.keyCode === 13 && !$(event.target).is("select, textarea")) {
      if (
        !$(event.target).is('[data-direction="next"]') &&
        !$(event.target).is('[data-direction="previous"]') &&
        !$(event.target).hasClass("tt-submit")
      ) {
        event.preventDefault();
      }
    }
  });
});

$("#swift_ifsc-input").blur(function (event) {
  const swift_code = $(this).val();

  $.ajax({
    url: window.location.pathname,
    type: "GET",
    dataType: "json",
    data: { swift_code: swift_code, product: filterData.product },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      const branchSwift = response.branch_swift;
      let bankData = banks.find((obj) => obj.id == branchSwift.bank_id);

      if (bankData) {
        $("#transaction-bank").val(bankData.id).trigger("change");
        // event.stopPropagation();
        // event.stopImmediatePropagation();
        // $("#transaction-branch").val(branchSwift.id).trigger("change");
        setTimeout(() => {
          $("#transaction-branch").val(branchSwift.id).trigger("change");
        }, 1000);
      }
    },
  });
});

// $(function () {
//   $("#transfer_transaction_customer_attributes_id_issue_at_id").on(
//     "change",
//     function () {
//       const value = this.value;
//       fetchIdTypes(value, filterData.product)
//     }
//   );
// });
// function fetchIdTypes(id_issue_at, product) {
//   $.ajax({
//     url: window.location.pathname,
//     type: "GET",
//     dataType: "json",
//     data: { id_type_changed: true, id_issue_at: id_issue_at, product_id: product },
//     error: function (xhr, status, error) {
//       console.error("AJAX Error: " + status + error);
//     },
//     success: function (response) {
//       if (!response) return;
//       if (!response.identification_types) return;
//       const idTypeEl = $(
//         "#transfer_transaction_customer_attributes_identification_type_id"
//       );
//       idTypeEl.empty().html("<option value=''>Please select</option>");
//       let selectedValue = null;
//       if (response.identification_types.length === 1)
//         selectedValue = response.identification_types[0].id;
//       response.identification_types.forEach(function (id_type) {
//         const selected = selectedValue == id_type.id ? "selected" : "";
//         idTypeEl.append(
//           '<option value="' +
//           id_type.id +
//           '" ' +
//           selected +
//           ">" +
//           id_type.name +
//           "</option>"
//         );
//       });
//     },
//   });
// }
$(function () {
  $('#new_transfer_transaction').on('submit', function () {
    $('#spinner-modal-trigger').trigger('click');
  });
});
